export default class StreamData {

    private _data: Blob | null;
    private _timeStamp: number;
    private _partNumber: number;

    constructor(data: Blob, timeStamp: number, partNumber: number) {
      this._data = data
      this._timeStamp = timeStamp
      this._partNumber = partNumber
    }

    get data() {
      return this._data
    }

    set data(data) {
      this._data = data
    }

    get timeStamp() {
      return this._timeStamp
    }

    set timeStamp(timeStamp) {
      this._timeStamp = timeStamp
    }

    get partNumber() {
      return this._partNumber
    }
}

/* eslint-disable no-invalid-this */
import StreamData from '@/models/StreamData'
import MediaService from '@/services/api/MediaService'
import StreamQueue from '../models/StreamQueue'
export default class StreamRecorder {

    private mediaService: MediaService
    private key: string
    private recorder?: MediaRecorder
    private partNumber: number = 0
    private stream: any
    private intervalSendData: any = 0
    private _queue: StreamQueue = new StreamQueue()
    private intevalQueuePaused: boolean = false
    private uploadInProgressCount: number = 0
    private maxuploadInProgress: number = 5
    private startTime = 0
    private stopCallback?: CallableFunction

    /*
    This variable tracks the total number of items that need to be sent. Unlike the queue's length, which fluctuates as chunks are attempted to be sent, this value remains stable, providing a consistent measure for calculating the percentage of data sent.
    */
    private remainingItemsToSend: number = 0

    constructor(vs, stream, key, options) {
      this.stream = stream
      this.mediaService = MediaService.build(vs, process.env.VUE_APP_API_PROCTORING_BASE_URL)
      this.key = key
    }

    startRecording = (options = {}, callbackStarted: Function | null) => {

      return new Promise((resolve, reject) => {

        try {

          this.recorder = new MediaRecorder(this.stream, options)

          this.recorder.ondataavailable = event => {

            if (typeof event.data === 'undefined') return
            if (event.data.size === 0) return

            const duration = event.timeStamp - this.startTime
            this.startTime = event.timeStamp

            this.partNumber++
            const streamData = new StreamData(event.data, duration / 1000, this.partNumber)
            this._queue.enqueue(streamData)
            this.remainingItemsToSend++
          }

          this.recorder.onstart = event => {
            console.log('onstart...', event)
            this.startTime = event.timeStamp
          }

          this.recorder.onstop = event => {
          }

          this.recorder.start(10000)

          if (callbackStarted !== null) {
            callbackStarted({
              emitted_at: new Date()
            })
          }

        } catch (e) {
          reject(e)
        }
      })
    }

    sendData() {
      this.intervalSendData = setInterval(() => {

        if (!this.intevalQueuePaused && this.uploadInProgressCount <= this.maxuploadInProgress) {
          let streamData: StreamData | null | undefined = this._queue.dequeue()
          if (streamData && streamData.data) {

            const data = new FormData()
            data.append('file_key', this.key)
            data.append('part_number', streamData.partNumber.toString())
            data.append('timestamp', streamData.timeStamp.toString())
            data.append('file', streamData.data)

            this.uploadInProgressCount++
            this.mediaService.uploadPart(data).then(response => {
              this.remainingItemsToSend--
              this.uploadInProgressCount--
              if (streamData) {
                streamData.data = null
              }
              streamData = null
            }, error => {
              this._queue.enqueue(streamData)
              this.uploadInProgressCount--
              if (streamData) {
                streamData.data = null
              }
              streamData = null
            })
          }
        }

        if (this._queue.length === 0 && this.stopCallback) {
          clearInterval(this.intervalSendData)
          this.stopRecording()
          this.stopCallback()
        }

      }, 5000)
    }

    stopRecording() {
      if (this.recorder) {
        const stream = this.recorder.stream

        if (this.recorder.state !== 'inactive') {
          this.recorder.stop()
        }

        const tracks = stream.getTracks()
        if (tracks) {
          tracks.forEach(track => {
            track.stop()
            if (stream) {
              stream.removeTrack(track)
            }
          })
        }
      }
    }

    stopSend(stopCallback) {
      this.stopCallback = stopCallback
    }

    get queue() {
      return this._queue
    }

    pauseIntevalQueue() {
      this.intevalQueuePaused = true
    }

    resumeIntevalQueue() {
      this.intevalQueuePaused = false
    }

    get remainingItems() {
      return this.remainingItemsToSend
    }

    get isStopped(): boolean {
      return this.recorder ? this.recorder.state === 'inactive' : true
    }
}

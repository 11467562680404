import StreamData from '../models/StreamData'
export default class StreamQueue {

    private queue: Array<StreamData | null | undefined> = []

    private _maxUploadLimit: number = 5000000 // Default to 5Mb

    constructor() {
      this.queue = []
    }

    enqueue(item: StreamData | null | undefined) {
      return this.queue.unshift(item)
    }

    dequeue(): StreamData | null | undefined {
      return this.queue.pop()
    }

    get length() {
      return this.queue.length
    }

    get maxUploadLimit() {
      return this._maxUploadLimit
    }

    set maxUploadLimit(value) {
      this._maxUploadLimit = value
    }
}

<template>
  <div
    tabindex="0"
    ref="overseer"
    @mouseup="selectTextListener"
    @copy="copyPasteCutHandler"
    @paste="copyPasteCutHandler"
    @cut="copyPasteCutHandler"
    @keyup="keyPressHandler"
    class="event-overseer"
  >
    <slot @enterQuestionnaireLink="enterQuestionnaireListener"></slot>
  </div>
</template>

<script>
import {EnterFullscreenEvent, LeaveFullscreenEvent, SelectedTextEvent, CopyTextEvent, CutTextEvent, PasteTextEvent, RightClickEvent, ResizeScreenEvent, PrintScreenEvent} from '@/models/events/DOMEvents'
// import {MediaScreenRejectEvent, MediaScreenShareEvent, UserMediaRejectEvent, UserMediaShareEvent} from '@/models/events/MediaEvents'

export default {
  props: {
    answerId: {
      type: Number,
      default: null
    },
    service: {
      type: Object,
      default: () => null
    },
    recording: {
      type: Boolean,
      default: false
    },
    screenfull: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    lastWindowHeight: null,
    persistenceQueue: [],
    queueInterval: null,
    cmdPressed: false,
    shiftPressed: false,
    keyPressTimeout: null,
    lastPrintScreenTime: 0,
    throttleInterval: 10000,
  }),

  computed: {
    queueSize() {
      return this.persistenceQueue.length
    }
  },

  watch: {
    '$store.state.windowHeight'(height) {

      const event = new ResizeScreenEvent({
        type: 'resize_screen',
        previousHeight: this.lastWindowHeight,
        newHeight: height,
        screen,
        window: {
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
          outerHeight: window.outerHeight,
          outerWidth: window.outerWidth
        }
      })

      this.registerDOMEvent(event)

      this.lastWindowHeight = height
    },
    'persistenceQueue.length'(length) {
      this.$emit('queueSizeChanged', length)
    }
  },

  methods: {
    //----------------------- QUEUE -------------------------------------------------
    enqueueEvent(event) {
      this.persistenceQueue.push(event)
    },
    processQueue() {
      if (this.persistenceQueue.length > 0 && this.answerId) {
        const event = this.persistenceQueue.splice(0, 1)[0]
        if (event) {
          this.service.registerEvent(this.answerId, event).then(
            (data) => {
              // OK
            },
            (error) => {
              // Back to the queue.
              this.enqueueEvent(event)
            }
          )
        } else {
          // For some reason the spliced event is an invalid object.
          throw this.$t('invalid-event-on-queue')
        }
      }
    },
    bootstrapQueue(time) { // Time is in ms
      this.queueInterval = setInterval(this.processQueue, time)
    },
    shutdownQueue() {
      clearInterval(this.queueInterval)
    },
    clearQueue() {
      this.persistenceQueue = []
    },
    resetQueue() {
      this.shutdownQueue()
      this.clearQueue()
    },
    //-------------------------------------------------------------------------------
    bootstrapFullscreenListener() {
      if (this.screenfull && this.screenfull.isEnabled) {
        this.screenfull.on('change', this.fullscreenListener)
      }
    },
    destroyFullscreenListener() {
      if (this.screenfull && this.screenfull.isEnabled) {
        this.screenfull.off('change', this.fullscreenListener)
      }
    },
    fullscreenListener(_event) {
      if (this.screenfull && this.screenfull.isEnabled) {
        const isFullscreen = this.screenfull.isFullscreen

        let newEvent = null

        if (!isFullscreen) {
          newEvent = new LeaveFullscreenEvent({
            type: 'leave_fullscreen',
            previousHeight: this.lastWindowHeight,
            screen,
            window: {
              innerHeight: window.innerHeight,
              innerWidth: window.innerWidth,
              outerHeight: window.outerHeight,
              outerWidth: window.outerWidth
            }
          })
        } else {
          newEvent = new EnterFullscreenEvent({
            type: 'enter_fullscreen',
            previousHeight: this.lastWindowHeight,
            screen,
            window: {
              innerHeight: window.innerHeight,
              innerWidth: window.innerWidth,
              outerHeight: window.outerHeight,
              outerWidth: window.outerWidth
            }
          })
        }

        if (newEvent) this.registerDOMEvent(newEvent)
      } else {
        this.$exceptionService.quickRegister(
          this.$t('erro-javascript'),
          'javascript_general',
          {
            type: 'fullscreen_disabled',
            name: this.$t('fullscreen-nao-habilitado'),
            message: this.$t('a-funcionalidade-de-tela-cheia-do-navegador-nao-foi-encontrada'),
          }
        )
      }
    },
    copyPasteCutHandler(event) {
      let eventObject = null
      switch (event.type) {
      case 'cut':
        eventObject = new CutTextEvent(event)
        break
      case 'copy':
        eventObject = new CopyTextEvent(event)
        break
      case 'paste':
        eventObject = new PasteTextEvent(event)
        break
      default:
        break
      }

      this.registerDOMEvent(eventObject)

      event.preventDefault()
      this.notifyWarning(this.$vs, this.$t('voce-nao-pode-copiar-colar-ou-recortar-qualquer-informacao-desta-prova'))
    },
    /**
     * This is not a true "select event listener", rather it listens all mouse ups...
     * and uses the selection API to detect whether a selection has happened.
     * It basically fires if mouseup is fired whilst something is selected.
     */
    selectTextListener(event) {
      // Listener code --------------------------------------------------------------
      const runListener = (mouseUpEvent, selection) => {
        const eventData = event
        eventData.selection = selection.toString()
        const eventObject = new SelectedTextEvent(event)
        this.registerDOMEvent(eventObject)
      }

      // PseudoEvent behavior -------------------------------------------------------
      if (window.getSelection) {
        const selectionObject = window.getSelection()
        const selectionText = selectionObject.toString()

        if (selectionText !== '') runListener(event, selectionObject)

      } else if (document.selection) {
        const selectionText =  document.selection.createRange().text
        // We dont want this code to execute. so we launch a warning
        console.warn('window getSelection API not found, using document.selection!')

        if (selectionText !== '') runListener(event, document.selection)
      }
    },
    rightClickListener(event) {
      const eventObject = new RightClickEvent(event)
      this.registerDOMEvent(eventObject)
    },
    questionnairePauseHandler(event) {
      this.registerDOMEvent(event)
    },
    entranceJustificationListener(event) {
      this.registerDOMEvent(event)
    },
    mediaSharedListener(event) {
      this.registerMediaEvent(event)
    },
    mediaRejectedListener(event) {
      this.registerMediaEvent(event)
    },
    enterQuestionnaireListener(event) {
      this.registerDOMEvent(event)
    },
    webConnectivityHandler(event) {
      this.registerDOMEvent(event)
    },
    connectionRateListener(event) {
      this.registerDOMEvent(event)
    },
    proctoringRecording(event) {
      this.registerDOMEvent(event)
    },
    // ------------------------------------------------------------------------------
    registerMediaEvent(event) {
      if (this.recording) this.enqueueEvent(event)
    },
    registerDOMEvent(event) {
      if (this.recording) this.enqueueEvent(event)
    },
    registerPrintScreenListener(event) {
      const eventObject = new PrintScreenEvent(event)
      this.enqueueEvent(eventObject)
    },
    keyPressHandler(event) {
      const currentTime = Date.now()

      // macOS detection
      if (event['key'] === 'Meta') {
        this.cmdPressed = true
      }
      if (event['key'] === 'Shift') {
        this.shiftPressed = true
      }

      if (this.cmdPressed && this.shiftPressed) {
        if (currentTime - this.lastPrintScreenTime > this.throttleInterval) {
          this.registerPrintScreenListener(event)
          this.lastPrintScreenTime = currentTime
        }
        this.resetKeyPressFlags()
      }

      // Windows detection
      if (event.key === 'PrintScreen' || event['key'] === 'PrintScreen') {
        if (currentTime - this.lastPrintScreenTime > this.throttleInterval) {
          this.registerPrintScreenListener(event)
          this.lastPrintScreenTime = currentTime
        }
      }

      // Reset flags after a short delay
      clearTimeout(this.keyPressTimeout)
      this.keyPressTimeout = setTimeout(this.resetKeyPressFlags, 500)
    },

    resetKeyPressFlags() {
      this.cmdPressed = false
      this.shiftPressed = false
    },
  },

  mounted() {
    this.bootstrapFullscreenListener()
    this.$root.$on('rightClick', this.rightClickListener)
    this.$root.$on('mediaReject', this.mediaRejectedListener)
    this.$root.$on('mediaShare', this.mediaSharedListener)
    this.$root.$on('webOnline', this.webConnectivityHandler)
    this.$root.$on('webOffline', this.webConnectivityHandler)
    this.$root.$on('connectionRate', this.connectionRateListener)
    this.$root.$on('questionnairePause', this.questionnairePauseHandler)
    this.$root.$on('leaveQuestionnairePause', this.questionnairePauseHandler)
    this.$root.$on('questionnaireEntranceJustification', this.entranceJustificationListener)
    this.$root.$on('enterQuestionnaireLink', this.registerDOMEvent)
    this.$root.$on('questionnaireFinishEvent', this.registerDOMEvent)
    this.$root.$on('startProctoringRecording', this.proctoringRecording)
    //this.$root.$on('printScreen', this.registerPrintScreenListener)
    this.bootstrapQueue(1000)
  },

  beforeDestroy() {
    this.resetQueue()
    this.destroyFullscreenListener()
  },

  destroyed() {
    this.$root.$off('rightClick', this.rightClickListener)
    this.$root.$off('mediaReject', this.mediaRejectedListener)
    this.$root.$off('mediaShare', this.mediaSharedListener)
    this.$root.$off('webOnline', this.webConnectivityHandler)
    this.$root.$off('webOffline', this.webConnectivityHandler)
    this.$root.$off('connectionRate', this.connectionRateListener)
    this.$root.$off('questionnairePause', this.questionnairePauseHandler)
    this.$root.$off('leaveQuestionnairePause', this.questionnairePauseHandler)
    this.$root.$off('questionnaireEntranceJustification', this.entranceJustificationListener)
    this.$root.$off('enterQuestionnaireLink', this.registerDOMEvent)
    this.$root.$off('startProctoringRecording', this.proctoringRecording)
    //this.$root.$off('printScreen', this.registerPrintScreenListener)
  }
}
</script>
